<template>
  <div class="plan-viewer">
    <NavInfo class="nav-info">
      <template slot="navigation">
        <NavItem @click="goBack">
          <ChevronPicto size="10px"
                        class="back-button-arrow" />
        </NavItem>
        <NavItem disabled
                 class="building-name">
          {{currentBuilding.name}}
        </NavItem>
      </template>
    </NavInfo>
    <buildingViewer :editorMode="false"
                    class="building-viewer" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import NavInfo from '@/app/components/layout/nav-info';
import NavItem from '@/app/components/layout/nav-info/components/navigation-item.vue';
import BuildingViewer from '@/app/views/building-viewer';
import BuildingSelectionMixin from '@/app/mixins/building-selection';
import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';

export default {
  name: 'PlanViewer',
  components: {
    NavInfo,
    NavItem,
    BuildingViewer,
    ChevronPicto,
  },
  mixins: [BuildingSelectionMixin],
  computed: {
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
  },
  methods: {
    ...mapMutations('ui', {
      setSidebarInState: 'SET_SIDEBARIN_STATE',
      setSidebarOutState: 'SET_SIDEBAROUT_STATE',
    }),
    goBack() {
      if (this.isWholeBuildingSelected(this.currentBuilding.uuid)) this.toggleBuildingSelect(this.currentBuilding);
      this.$store.commit('building/setSelectedBuilding', null);
      this.setSidebarInState(true);
      this.setSidebarOutState('small');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.plan-viewer
  display flex
  flex-direction column
  padding 0.1px
  width 100%
  height 100%
  .nav-info
    margin $space-2 $space-2 0
    .back-button-arrow
      transform rotate(90deg)
    .building-name
      font-weight $fw-bold
  .building-viewer
    z-index 1
    height 100%
    @media screen and (max-width: 500px)
      -webkit-overflow-scrolling touch
      -webkit-transform translateZ(0px)
      -webkit-transform translate3d(0, 0, 0)
      -webkit-perspective 1000
</style>